<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{new_item?.title}}</ion-title>
    <ion-buttons slot="start" (click)="dismiss()">
      <ion-button>
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" (click)="share()">
      <ion-button>
        <ion-icon name="share"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <div *ngIf="new_item?.image" class="parallax"
    [style.background-image]="'url(' + new_item?.image + ')'"></div> -->

  <ion-img [src]="new_item?.image" *ngIf="new_item?.image"></ion-img>
  <div class="ion-padding-horizontal">
    <ion-text color="dark">
      <h1>{{new_item?.title}}</h1>
    </ion-text>
    <hr>
    <ion-text color="medium">
      <h5>{{new_item?.date | localShortDate}}</h5>
    </ion-text>
    <div class="bigger-font">
      <p *ngIf="new_item?.description" class="description">{{new_item?.description}}</p>
      <div [innerHTML]="new_item?.text"></div>
    </div>


    <ion-badge mode="ios" *ngFor="let subject of new_item?.subject" class="ion-float-left">{{subject}}
    </ion-badge>
  </div>
</ion-content>
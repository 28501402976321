/**
  * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { SessionService } from '@services/session/session.service';

@Pipe({
    name: 'localShortDate'
})
export class LocalShortDatePipe implements PipeTransform {

    constructor(private session: SessionService) { }

    transform(value: any) {
        if (!value) { return ''; }
        // if (!format) { format = 'shortDate'; }
        // if (!format) {
        let format = this.session.locale=='eu'?'yyyy/MM/dd':'dd/MM/yyyy'; 
            // }
        return formatDate(value, format, this.session.locale);       
    }
}
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@services/session/session.service';
import { Preferences } from '@capacitor/preferences';

export const LANGUAGES = [{
  name: 'BASQUE',
  code: 'eu'
}, {
  name: 'SPANISH',
  code: 'es'
}];


@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  constructor(private translate:TranslateService, private session: SessionService) { }

  getCurrentLanguage() {
    return this.translate.getDefaultLang();
  }

  async changeCurrentLanguage(lang_code) {
    return await new Promise(resolve => {
      Preferences.set({
        key: 'language_code',
        value: lang_code
      }).then(() => {
        this.translate.use(lang_code).subscribe(() => {
          this.translate.setDefaultLang(lang_code);
          this.session.registerCulture(lang_code || 'eu');
          resolve(lang_code);
        });
      })
    });
  }

  async loadSavedLanguage() {
    const lang_code = await Preferences.get({ key: 'language_code' });
    this.translate.setDefaultLang(lang_code.value || 'eu');
    this.session.registerCulture(lang_code.value || 'eu');
  }

  getTranslationStrings(key_array: [string]) {
    return this.translate.get(key_array);
  }


  async initializeLanguage() {
    return await new Promise((resolve, reject) => {
      if (LANGUAGES.map(item => item.code).includes(this.translate.getBrowserLang())) {
        this.changeCurrentLanguage(this.translate.getBrowserLang()).then( ()=> {
          resolve(this.translate.getBrowserLang())
        }).catch(err => {
          reject(err);
        });
      }
    });
  }


}

import { Injectable } from '@angular/core';
import "@capacitor-community/firebase-analytics";

import { Plugins } from "@capacitor/core";

const { FirebaseAnalytics } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }
  enableCollection() {
    FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  }
  setScreenName(screen_name) {
    FirebaseAnalytics.setScreenName({ screenName: screen_name, nameOverride: screen_name, });
  }
}

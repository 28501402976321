import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BRANCH_URL_API, options, BRANCH_KEY } from './branchio_constants';
import { Share } from '@capacitor/share';
@Injectable({
  providedIn: 'root'
})
export class BranchioService {

  constructor(private httpClient: HttpClient) { }

  aproba() {
    let option: options = {
      branch_key: BRANCH_KEY,
      feature: "onboarding",
      tags: ["one", "two", "three"],
      data: {
        $canonical_identifier: "content/123",
        $og_title: "Title from Deep Link",
        $og_description: "Description from Deep Link",
        $og_image_url: "http://www.lorempixel.com/400/400/",
        $desktop_url: "http://www.example.com",
        custom_boolean: true,
        custom_integer: 1243,
        custom_string: "everything",
        custom_array: [1, 2, 3, 4, 5, 6],
        custom_object: { "random": "dictionary" }
      }
    };
    this.createUniversalLink(option)
  }

  simpleCreateShareUniversalLink(url: string, title?: string, description?: string, img_url?: string) {
    return new Promise((resolve, reject) => {
      let simple_options: options = {
        branch_key: BRANCH_KEY,
        data: {
          $og_title: title || null,
          $og_description: description || null,
          $og_image_url: img_url || null,
          $desktop_url: url || null,
          $original_url: url || null,
          $web_only: url || null,
          $fallback_url: url || null
        }
      }
      this.createUniversalLink(simple_options).subscribe(res => {
        Share.share({
          title: title,
          text: description,
          url: res.url,
          dialogTitle: title
        }).then(() => resolve({ "OK": "Shared successfully" })).catch(err => reject({ "Error sharing": err }))
      }, err => { console.error("Branch err: ", err); reject({ "Error getting branchIO url": err }) })
    })
  }
  createUniversalLink(options: options) {
    let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
    return this.httpClient.post<any>(BRANCH_URL_API, options, { headers });
  }

}

import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BranchioService } from '@services/branchio/branchio.service';
import { UtilService } from '@services/util/util.service';

@Component({
  selector: 'app-agenda-details',
  templateUrl: './agenda-details.page.html',
  styleUrls: ['./agenda-details.page.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AgendaDetailsPage implements OnInit {
  @Input() agenda_item;
  constructor(private modalCtrl: ModalController,
    private branchioService:BranchioService,
    private utilService:UtilService,
    private _element: ElementRef) { }

  ngOnInit() {
    this.agenda_item.text = this.utilService.HTMLSanitizer(this.agenda_item.text);
    this._enableDynamicHyperlinks();
  }
  share(){
    this.branchioService.simpleCreateShareUniversalLink(this.agenda_item.url, this.agenda_item.title, this.agenda_item.description, this.agenda_item.image).then(res=>{
      console.log("SHARED: ", res);
    }).catch(err=>console.log("ERROR SHARING: ", err))
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  private _enableDynamicHyperlinks(): void {
    setTimeout(() => {
      const urls: any = this._element.nativeElement.querySelectorAll('a');
      urls.forEach((url) => {
        url.addEventListener('click', (event) => {
          event.preventDefault();
          let link = event.target.href || url.href;
          this.utilService.openBrowser(link);
        }, false);
      })
    }, 300);
  }
}

export const API_ENDPOINT = 'http://eibar.korpoweb.com/'
// export const API_ENDPOINT = 'http://anboto.cs:3199/eibarkoudala/'
// export const TOPICS_API_ENDPOINT = 'https://udalak-eibar.firebaseio.com/topics.json';
export const APP_ROUTES = {
    new_list:'albisteak',
    agenda_list:'agenda',
    complain:'kexak',
    udala:'udala',
    inbox:'inbox',
    settings:'ezarpenak',
    welcome:'ongi-etorri'
}
export const DB_KEYS = {
    first_config:'first_config',
    topics:'topics',
    enable_notifications:'enable_notifications'
}

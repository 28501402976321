import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BranchioService } from '@services/branchio/branchio.service';
import { UtilService } from '@services/util/util.service';

@Component({
  selector: 'app-new-details',
  templateUrl: './new-details.page.html',
  styleUrls: ['./new-details.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewDetailsPage implements OnInit {
  @Input() new_item;
  // @HostListener('window:blur', ['$event'])
  // iframeClickEvent(event) {
  //   if (document.activeElement['src'] && this.utilService.extractHostname(document.activeElement['src']) != "www.youtube.com") {
  //     // document.activeElement.setAttribute("sandbox", "")
  //     event.preventDefault();
  //     this.utilService.openBrowser(document.activeElement['src']);
  //   }
  //   setTimeout(() => window.focus(), 100);
  // }

  constructor(private modalCtrl: ModalController,
    private branchioService: BranchioService,
    private utilService: UtilService,
    private _element: ElementRef) {
  }

  async ngOnInit() {
    this.new_item.text = this.utilService.HTMLSanitizer(this.new_item.text);
    this._enableDynamicHyperlinks();
  }

  share() {
    this.branchioService.simpleCreateShareUniversalLink(this.new_item.url, this.new_item.title, this.new_item.description, this.new_item.image).then(res => {
      console.log("SHARED: ", res);
    }).catch(err => console.log("ERROR SHARING: ", err))
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss();
  }

  private _enableDynamicHyperlinks(): void {
    setTimeout(() => {
      const urls: any = this._element.nativeElement.querySelectorAll('a');
      urls.forEach((url) => {
        url.addEventListener('click', (event) => {
          event.preventDefault();
          let link = event.target.href || url.href;
          this.utilService.openBrowser(link);
        }, false);
      })
    }, 300);
  }

}

<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{agenda_item?.title}}</ion-title>
    <ion-buttons slot="start" (click)="dismiss()">
      <ion-button>
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" (click)="share()">
      <ion-button>
        <ion-icon name="share"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-img [src]="agenda_item?.image" *ngIf="agenda_item?.image"></ion-img>
  <div class="ion-padding-horizontal">
    <ion-text color="dark">
      <h1 class="ion-no-margin ion-margin-top title">{{agenda_item?.title}}</h1>
      <hr class="ion-no-margin">
    </ion-text>

    <ion-text color="medium">
      <h5>{{agenda_item?.start_date | localShortDate}} - {{agenda_item?.end_date | localShortDate}}</h5>
    </ion-text>

    <ion-text *ngIf="agenda_item?.location || agenda_item?.start_date">
      <p class="ion-no-margin" *ngIf="agenda_item?.location"><b>{{ 'WHERE' | translate | uppercase }}: </b>
        {{agenda_item?.location}}</p>
      <p class="ion-no-margin" *ngIf="agenda_item?.start_date"><b>{{ 'HOUR' | translate | uppercase}}:
        </b>{{agenda_item?.start_date | date:'HH:mm'}}</p>
    </ion-text>

    <div class="bigger-font">
      <p *ngIf="agenda_item?.description">{{agenda_item?.description}}</p>
      <div [innerHTML]="agenda_item?.text"></div>
    </div>

    <ion-badge mode="ios" *ngFor="let subject of agenda_item?.subject" class="ion-float-left">{{subject}}
    </ion-badge>
  </div>
</ion-content>
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { API_ENDPOINT, DB_KEYS } from '@services/constants';
import { map } from 'rxjs/operators';
import { InboxDetailsPage } from '@pages/inbox/inbox-details/inbox-details.page';
import { ModalController } from '@ionic/angular';
import { UtilService } from '@services/util/util.service';
import { LanguageService } from '@services/language/language.service';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  constructor(private httpClient: HttpClient,
    private modalController: ModalController,
    private languageService:LanguageService,
    private utilService: UtilService) { }

  async getInboxList() {
    let topics = await Preferences.get({ key: DB_KEYS.topics });
    let subscribed_topics = JSON.parse(topics.value);
    let topics_join = subscribed_topics.filter(item => item.subscribed == true).map(item => item.id).join();
    let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
    let res = await this.httpClient.get<any>(API_ENDPOINT + this.languageService.getCurrentLanguage()+'/@inbox?tags=' + topics_join + '&b_size=4', { headers }).toPromise();
    return {
      items: res['items'],
      nextpage: res['batching'] ? res['batching']['next'] || null : null
    }
  }


  async getInboxListNextpage(nextpage_url: string) {
    let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
    let res = await this.httpClient.get<any>(nextpage_url, { headers }).toPromise();
    return {
      items: res['items'],
      nextpage: res['batching'] ? res['batching']['next'] : null
    }
  }

  getInboxDetails(url: string) {
    let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
    return this.httpClient.get<any>(url, { headers }).pipe(map(res => {
      return {
        title: res.title,
        description: res.description.data,
        url:res['@id'],
        text: res.text.data,
        image: res.image ? res.image.scales.large.download : null,
        date: res.effectiveDate ? res.effectiveDate : null,
        start_date: res.startDate ? res.endDate : null,
        end_date: res.endDate ? res.endDate : null,
        location: res.location ? res.location : null,
        contact: res.contactPhone || res.contactEmail ? { phone_number: res.contactPhone ? res.contactPhone : null, email: res.contactEmail ? res.contactEmail : null } : null,
        subject: res.subject ? res.subject : null
      }
    }));
  }


  async openInboxDetails(title: string, url: string) {
    if (this.getURLOrigin(url) == API_ENDPOINT) {
      // console.log("EIBAR.EUS da")
      const modal = await this.modalController.create({
        component: InboxDetailsPage,
        componentProps: { inbox: { title: title, url: url } },
        mode: 'ios'
      });
      return await modal.present();
    } else {
      // console.log("EZ DA EIBAR.EUS")
      this.utilService.openBrowser(url)
    }
  }
  getURLOrigin(url) {
    var l = document.createElement("a");
    l.href = url;
    return `${l.origin}/`;
  }

}

export const BRANCH_API = 'https://api2.branch.io/v1/';
export const BRANCH_URL_API = `${BRANCH_API}url`;
export const BRANCH_KEY = 'key_live_mdINBVfJcjf9OqG7g34eLfpdvzorcuaF'


export interface options {
    branch_key: string,
    channel?: "facebook" | "instagram",
    feature?: "onboarding",
    campaign?: "new product",
    stage?: "new user",
    tags?: ["one", "two", "three"],
    data: {
        $canonical_identifier?: string | "content/123",
        $og_title?: string | "Title from Deep Link",
        $og_description?: string | "Description from Deep Link",
        $og_image_url?: string | "http://www.lorempixel.com/400/400/",
        original_url?:string,
        custom_boolean?: boolean | true,
        custom_integer?: number | 1243,
        custom_string?: string | "everything",
        custom_array?: [] | [1, 2, 3, 4, 5, 6],
        custom_object?: {} | { "random": "dictionary" },
        $original_url?:string, 
        $canonical_url?:string,

        //To redirect to url if app is not installed
        $desktop_url: string | "http://www.example.com",
        $fallback_url?:string,
        $ios_url?:string,
        $android_url?:string,
        $url?: string,
        $web_only?: string,
        $ipad_url?: string,
        // -- To redirect to url if app is not installed
    }
}
<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{inbox?.title}}</ion-title>
    <ion-buttons slot="start" (click)="dismiss()">
      <ion-button>
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" (click)="share()">
      <ion-button>
        <ion-icon name="share"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-img [src]="inbox?.image" *ngIf="inbox?.image"></ion-img>
  <div class="ion-padding-horizontal">
    <ion-text color="dark">
      <h1 class="ion-no-margin ion-margin-top">{{inbox?.title}}</h1>
      <hr class="ion-no-margin">
    </ion-text>

    <ion-text color="medium" *ngIf="inbox?.start_date || inbox?.end_date;else only_date">
      <h5>{{inbox?.start_date | localShortDate}} - {{inbox?.end_date | localShortDate}}</h5>
    </ion-text>

    <ng-template #only_date>
      <ion-text color="medium" *ngIf="inbox?.date">
        <h5>{{inbox?.date | localShortDate}}</h5>
      </ion-text>
    </ng-template>

    <ion-text *ngIf="inbox?.location || inbox?.start_date">
      <p class="ion-no-margin" *ngIf="inbox?.location"><b>{{ 'WHERE' | translate | uppercase }}: </b>
        {{inbox?.location}}</p>
      <p class="ion-no-margin" *ngIf="inbox?.start_date"><b>{{ 'HOUR' | translate | uppercase}}:
        </b>{{inbox?.start_date | date:'HH:mm'}}</p>
    </ion-text>

    <div class="bigger-font">
      <p *ngIf="inbox?.description"><b>{{inbox?.description}}</b></p>
      <div [innerHTML]="inbox?.text"></div>
    </div>

    <ion-badge mode="ios" *ngFor="let subject of inbox?.subject" class="ion-float-left">{{subject}}
    </ion-badge>
  </div>
</ion-content>
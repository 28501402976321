import { Injectable } from '@angular/core';
// import { FCM } from "capacitor-fcm";
// const fcm = new FCM();

import { FCM } from '@capacitor-community/fcm';

import { PushNotifications } from "@capacitor/push-notifications";
import { LanguageService } from '@services/language/language.service';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { API_ENDPOINT, DB_KEYS } from '@services/constants';
import { InboxService } from '@services/inbox/inbox.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  constructor(private languageService: LanguageService,
    private inboxService:InboxService,
    private httpClient: HttpClient) {
    // this.initializeReceived();
  }

  async getEnableNotifications() {
    return JSON.parse(await (await Preferences.get({ key: DB_KEYS.enable_notifications })).value);
  }

  async setEnableNotifications(value: boolean) {
    await Preferences.set({ key: DB_KEYS.enable_notifications, value: String(value) });
  }

  async enableNotificationsToggle() {
    let enabled_notifications = await this.getEnableNotifications();
    if (enabled_notifications)
      await this.unSubscribeToAllTopics();
    else
      await this.subscribeToAllTopics();
  }

  async getAvailableTopics() {
    let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
    let topics = await this.httpClient.get<any>(API_ENDPOINT + '@app-tags', { headers }).toPromise();
    let topic_list = [];
    topics.items.forEach(topic => {
      topic_list.push({ id: `${topic.number}_eu`, name: topic['eu'] });
      topic_list.push({ id: `${topic.number}_es`, name: topic['es'] });
    })
    return topic_list;
  }

  async getSubscribedTopics() {
    let server_topics = await this.getAvailableTopics();
    const topics = await Preferences.get({ key: DB_KEYS.topics });
    let subscribed_topics = JSON.parse(topics.value);
    server_topics.map(server_topic => {console.log(server_topic);server_topic['subscribed'] =
    (subscribed_topics.find(subscribed_topic => subscribed_topic.id == server_topic.id))?
    (subscribed_topics.find(subscribed_topic => subscribed_topic.id == server_topic.id)['subscribed'] ? true : false)
    :subscribed_topics.push({id:server_topic.id, name:server_topic.name, subscribed:true})
  })
    return server_topics;
  }

  async subscribeToAllTopics() {
    let topics = await this.getAvailableTopics();
    topics.forEach(topic => {
      if (topic.id.slice(-2) == this.languageService.getCurrentLanguage()) {
        topic.subscribed = true;
        this.subscribeToTopic(topic.id).then(r => console.log(`${r}  -  ${topic.id}`))
      } else {
        topic.subscribed = false;
        this.unSubscribeToTopic(topic.id).then(r => console.log(`${r}  -  ${topic.id}`))
      }
    });
    await Preferences.set({ key: DB_KEYS.topics, value: JSON.stringify(topics) });
    this.setEnableNotifications(true);
  }

  async subscribeToggle(topic_id: string) {
    let subscribed_topics = await this.getSubscribedTopics();
    let object = subscribed_topics.find(topic => topic.id == topic_id);
    // SUBCRIBED TO TOPIC_ID
    if (!object.subscribed) {
      this.subscribeToTopic(object.id);
    } else {
      this.unSubscribeToTopic(object.id);
    }
    //FIND, UPDATE AND SAVE SUBSCRIBED ITEM
    let index_of_item = await subscribed_topics.findIndex(obj => obj.id == object.id);
    subscribed_topics[index_of_item].subscribed = !subscribed_topics[index_of_item].subscribed;
    await Preferences.set({ key: DB_KEYS.topics, value: JSON.stringify(subscribed_topics) });
  }

  async changeLanguageTopics() {
    let subscribed_topics = await this.getSubscribedTopics();
    let subs_topic = subscribed_topics.filter(topic => topic.subscribed == true);
    let ids = subs_topic.map(topic => topic.id.split("_")[0]);
    // console.log("SUBCRIBED_IDS: ", ids)
    let new_lang = this.languageService.getCurrentLanguage();
    // UNSUBSCRIBE TOPICS FROM OLD LANGUAGE
    subs_topic.forEach(topic => {
      subscribed_topics.find(t => t.id == topic.id).subscribed = false;
      // UNSUBSCRIBE NATIVE TOPIC_ID
      this.unSubscribeToTopic(topic.id);
    });

    // UNSUBSCRIBE TOPICS TO NEW LANGUAGE
    ids.forEach(id => {
      subscribed_topics.find(t => t.id == `${id}_${new_lang}`).subscribed = true;
      /* SUBSCRIBE NATIVE TOPIC ID */
      this.subscribeToTopic(`${id}_${new_lang}`);
    });
    await Preferences.set({ key: DB_KEYS.topics, value: JSON.stringify(subscribed_topics) });
  }

  async unSubscribeToAllTopics() {
    let topics = await this.getSubscribedTopics();
    topics.forEach(topic => {
      if (topic.subscribed) {
        topic.subscribed = false;
        this.unSubscribeToTopic(topic.id).then(r => console.log(`${r}  -  ${topic.id}`));
      }
    });
    await Preferences.set({ key: DB_KEYS.topics, value: JSON.stringify(topics) });
    this.setEnableNotifications(false);
  }

  subscribeToTopic(id: string) {
    return new Promise((resolve, reject) => {
      // Subscribe to a specific topic
      PushNotifications.register().then(_ => {
        FCM.subscribeTo({ topic: id })
          .then((r) => {
            resolve(r);
            // console.log(`subscribed to topic: ${r}`);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }).catch(err => { console.log(JSON.stringify(err)); reject(err) });
    });
  }

  unSubscribeToTopic(topic_id: string) {
    return new Promise((resolve, reject) => {
      // Unsubscribe from a specific topic
      FCM.unsubscribeFrom({ topic: topic_id })
        .then((r) => {
          resolve(r);
          // console.log(`unsubscribed from topic: ${r}`);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }




  getRemoteToken() {
    /**
    * get remote token
    *
    * Recommended to use this instead of  PushNotifications.addListener("registration", ...);
    * because the native capacitor method, for apple, returns the APN's token
    **/
    FCM.getToken()
      .then((r) => console.log(`Token ${r.token}`))
      .catch((err) => console.log(err));
  }

  removeLocalFCMInstance() {
    // remove local fcm instance completely
    FCM.deleteInstance()
      .then(() => console.log(`Token deleted`))
      .catch((err) => console.log(err));
  }


  initializeReceived() {
    PushNotifications.requestPermissions().then((result: any) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
    PushNotifications.addListener('pushNotificationReceived', (notification: any) => {
      console.log('Push received: ', JSON.stringify(notification));
    });

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: any) => {
        if (notification.notification.data.url) {
          this.inboxService.openInboxDetails(
            notification.notification.data.title,
            notification.notification.data.url,
          );
        }
        // console.log('Push action performed: ', notification);
      },
    );
  }

}
import { NgModule } from '@angular/core';
import { LocalShortDatePipe } from './local-short-date/local-short-date.pipe';
import { CommonModule } from '@angular/common';  

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LocalShortDatePipe
    ],
    exports: [
        LocalShortDatePipe
    ]
})
export class PipeSharedModule {}
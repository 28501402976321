import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { API_ENDPOINT } from '@services/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from '@services/language/language.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Preferences } from '@capacitor/preferences';
import { Browser } from '@capacitor/browser';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(public toastController: ToastController,
    private httpClient: HttpClient,
    public translate: TranslateService,
    private languageService: LanguageService,
    public alertController: AlertController,
    private sanitizer:DomSanitizer) {
  }


  async getStorage(key: string) {
    const ret = await Preferences.get({ key: key });
    return await JSON.parse(ret.value);
  }

  async setStorage(key: string, value) {
    return await Preferences.set({
      key: key,
      value: JSON.stringify(value),
    });
  }



  async presentToast(text_key: string, duration: number) {
    let res = await this.translate.get([text_key]).toPromise();
    const toast = await this.toastController.create({
      message: res[text_key],
      duration: duration
    });
    toast.present();
  }


  async presentAlert(header: string, message: string) {
    let res = await this.translate.get(['OK', header, message]).toPromise();
    console.log("RES: ", res)
    const alert = await this.alertController.create({
      header: res[header],
      message: res[message],
      buttons: [res['OK']]
    });
    await alert.present();
  }

  // async presentToast(text) {
  //   const toast = await this.toastController.create({
  //     message: text,
  //     duration: 2000,
  //     color: "primary"
  //   });
  //   toast.present();
  // }



  async openBrowser(url: string) {
    if (url)
      await Browser.open({ url: url });
    else
      console.log("Empty url: ", url)
  }

  getAppData() {
    return new Observable(observer => {
      let headers = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });
      return this.httpClient.get<any>(API_ENDPOINT + this.languageService.getCurrentLanguage() + '/app/info?fullobjects', { headers }).subscribe(res => {
        observer.next(
          res.items.map(item => {
            return {
              title: item.title,
              description: item.text.data
            }
          }));
      });
    })
  }

  HTMLSanitizer(html){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

//   extractHostname(url) {
//     var hostname;
//     //find & remove protocol (http, ftp, etc.) and get hostname

//     if (url.indexOf("//") > -1) {
//         hostname = url.split('/')[2];
//     }
//     else {
//         hostname = url.split('/')[0];
//     }

//     //find & remove port number
//     hostname = hostname.split(':')[0];
//     //find & remove "?"
//     hostname = hostname.split('?')[0];

//     return hostname;
// }

}

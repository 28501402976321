import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NewDetailsPageModule } from '@pages/new/new-details/new-details.module';
import { AgendaDetailsPageModule } from '@pages/agenda/agenda-details/agenda-details.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { SuperTabsModule } from '@ionic-super-tabs/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SchemaFormModule} from "ngx-schema-form";
import { SessionService } from '@services/session/session.service';
import { InboxDetailsPageModule } from '@pages/inbox/inbox-details/inbox-details.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: ''
    }),
    AppRoutingModule,
    HttpClientModule,
    NewDetailsPageModule,
    AgendaDetailsPageModule,
    InboxDetailsPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SuperTabsModule.forRoot(),
    // FormsModule,
    BrowserAnimationsModule,
    // ReactiveFormsModule,
    SchemaFormModule.forRoot()
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LOCALE_ID,
      deps: [SessionService],
      useFactory: (sessionService) => sessionService.getLocale()
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

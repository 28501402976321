import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  }
  // {
  //   path: 'inbox-details',
  //   loadChildren: () => import('./pages/inbox/inbox-details/inbox-details.module').then( m => m.InboxDetailsPageModule)
  // }
  // { path: 'new-list', loadChildren: './new-list/new-list.module#NewListPageModule' },
  // { path: 'new-details', loadChildren: './news/new-details/new-details.module#NewDetailsPageModule' },
  // { path: 'agenda-list', loadChildren: './agenda/agenda-list/agenda-list.module#AgendaListPageModule' },
  // { path: 'agenda-details', loadChildren: './agenda/agenda-details/agenda-details.module#AgendaDetailsPageModule' },
  // { path: 'complain', loadChildren: './complain/complain.module#ComplainPageModule' },
  // { path: 'udala', loadChildren: './udala/udala.module#UdalaPageModule' },
  // { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from '@services/util/util.service';
import { LanguageService } from '@services/language/language.service';
import { DB_KEYS } from '@services/constants';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { InboxDetailsPage } from '@pages/inbox/inbox-details/inbox-details.page';
import { NotificationsService } from '@services/notifications/notifications.service';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private utilService: UtilService,
    private languageService: LanguageService,
    private router: Router,
    private modalController: ModalController,
    private notificationService: NotificationsService,
    private elRef: ElementRef,
    private analyticsService: AnalyticsService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    let primary_color_shade = '#87002d';
    StatusBar.setStyle({ style: Style.Dark });
    StatusBar.setBackgroundColor({ color: primary_color_shade });
    StatusBar.show();
    BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
      // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
      // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
      if (
        event.referringParams['+clicked_branch_link'] &&
        event.referringParams['+match_guaranteed']
      ) {
        this.openBranchModal(event.referringParams);
      }
    });
    BranchDeepLinks.addListener('initError', (error: any) => {
      console.error(error);
    });
    this.notificationService.initializeReceived();
    this.languageService.loadSavedLanguage();
    // let primary_color = "#990033";
    // this.headerColor.tint(primary_color);
    this.goToWelcomePage();
    // this.platform.ready().then(()=>{
    //   SplashScreen.hide();
    // });
    this.analytics();
  }

  goToWelcomePage() {
    this.utilService.getStorage(DB_KEYS.first_config).then((res) => {
      if (!res)
        this.languageService
          .initializeLanguage()
          .then(() => this.router.navigateByUrl('welcome'))
          .catch((err) => console.log('Error: ', err));
    });
  }

  async openBranchModal(share_link_data) {
    const modal = await this.modalController.create({
      component: InboxDetailsPage,
      componentProps: {
        inbox: {
          url: share_link_data['$original_url'],
          title: share_link_data['$og_title'],
        },
      },
      mode: 'ios',
    });
    return await modal.present();
  }

  analytics() {
    this.analyticsService.enableCollection();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        console.log('NavigationStart: ', event);
      }

      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          let a = this.elRef.nativeElement.getElementsByTagName('ion-title');
          let page_screen_title = a[a.length - 1].innerText
            ? a[a.length - 1].innerText
            : 'Eibar App';
          console.log('Screen title: ', page_screen_title);
          this.analyticsService.setScreenName(page_screen_title);
        }, 300);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        console.log('NavigationError');
        // Present error to user
        console.log(event.error);
      }
    });
  }
}

import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InboxService } from '@services/inbox/inbox.service';
import { BranchioService } from '@services/branchio/branchio.service';
import { UtilService } from '@services/util/util.service';

@Component({
  selector: 'app-inbox-details',
  templateUrl: './inbox-details.page.html',
  styleUrls: ['./inbox-details.page.scss'],
  encapsulation:ViewEncapsulation.None
})
export class InboxDetailsPage implements OnInit {
  @Input() inbox;
  constructor(private modalCtrl: ModalController,
    private inboxService:InboxService,
    private branchioService:BranchioService,
    private utilService:UtilService,
    private _element: ElementRef) { }

  ngOnInit() {
    this.getInboxDetails();
  }

  getInboxDetails(){
    this.inboxService.getInboxDetails(this.inbox.url).subscribe(res=>{
      this.inbox = res;
      this.inbox.text = this.utilService.HTMLSanitizer(res.text);
      this._enableDynamicHyperlinks();
    })
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  share(){
    this.branchioService.simpleCreateShareUniversalLink(this.inbox.url, this.inbox.title, this.inbox.description, this.inbox.image || '').then(res=>{
      // console.log("SHARED: ", res);
    }).catch(err=>console.log("ERROR SHARING: ", err))
  }

  private _enableDynamicHyperlinks(): void {
    setTimeout(() => {
      const urls: any = this._element.nativeElement.querySelectorAll('a');
      urls.forEach((url) => {
        url.addEventListener('click', (event) => {
          event.preventDefault();
          let link = event.target.href || url.href;
          this.utilService.openBrowser(link);
        }, false);
      })
    }, 300);
  }
}

import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEu from '@angular/common/locales/eu';

@Injectable({ providedIn: 'root' })
export class SessionService {

  private _locale: string;

  set locale(value: string) {
      this._locale = value;
  }
  get locale(): string {
      return this._locale || 'eu';
  }
  getLocale(): string {
    return this._locale || 'eu';
}

  registerCulture(culture: 'es'|'eu'|string) {
      if (!culture) {
          return;
      }
      this.locale = culture;

      // Register locale data since only the eu locale data comes with Angular
      switch (culture) {
          case 'es': {
              registerLocaleData(localeEs);
              break;
          }
          case 'eu': {
              registerLocaleData(localeEu);
              break;
          }
      }
  }
}
